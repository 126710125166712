
import { defineComponent, ref, onMounted } from "vue";
import { Form } from "vee-validate";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
name: "healthsheet2new",
components: {

  Form,
},
async mounted() {
  this.init();
},
data() {


   const months= [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const totalMonths = 0;

  return {

    months,
    totalMonths,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Financial Health Ratios", ["Financial Statement"]);
  },
  onSubmit(values){
    console.log(values);
  }
},
});
